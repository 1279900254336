import styled from "styled-components";

const Grid = styled.div`
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(400px, 1fr));
  grid-auto-rows: 200px;
  grid-gap: 10px;

  @media (max-width: 800px) {
    grid-template-columns: 1fr;
  }
`;

export default Grid;
