import 'aframe'
import { useEffect, useState } from 'react'
import { useParams } from 'react-router-dom'
import { headerHeight } from '../App'
import items from '../data/objects.json'

const Model = () => {
  const { id } = useParams()
  const { src, mtl } = items[id]

  const [height, setHeight] = useState(() => window.innerHeight - headerHeight)

  const resize = () => {
    setHeight(window.innerHeight - headerHeight)
  }

  useEffect(() => {
    window.addEventListener('resize', resize)

    return () => window.removeEventListener('resize', resize)
  }, [])

  /*
  {/* <a-assets>
    <a-asset-item
    id="city"
    src="https://cdn.glitch.com/36cb8393-65c6-408d-a538-055ada20431b/Astronaut.glb?1542147958948"
    ></a-asset-item>
    </a-assets>
    <Entity light={{ type: "point" }} />
    <Entity gltf-model={"#city"} />

    <a-sky color="#ECECEC"></a-sky>


    <a-entity obj-model="obj: #tree-obj; mtl: #tree-mtl"></a-entity>
    */
  return (
    <a-scene embedded style={{ width: '100%', height: height }}>
      <a-assets>
        <a-asset-item id="obj" src={src}></a-asset-item>
        <a-asset-item id="mtl" src={mtl}></a-asset-item>
      </a-assets>
      <a-camera
        camera=""
        position="0 200 100"
        rotation=""
        //look-controls="enabled:false"
        wasd-controls=""
      ></a-camera>
      <a-entity
        drag-rotate-component
        id="obj"
        //rotation="0 0 0"
        //position="0 0 -400"
        //scale="0.2 0.2 0.2"
        obj-model="obj: #obj; mtl: #mtl"
        material="color: red"
        //animation="property: rotation; to: 0 360 0; loop: true; dur: 10000"
      ></a-entity>
    </a-scene>
  )
}

export default Model
