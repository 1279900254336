import { useParams } from 'react-router-dom'

import items from '../data/videos.json'
import StereoVideo from './StereoVideo'
import YoutubeVideo from './YoutubeVideo'

const Video = () => {
  const { id } = useParams()
  const { type, src } = items[id]

  switch (type) {
    case 'youtube':
      return <YoutubeVideo videoId={src} />
    default:
      return <StereoVideo src={src} />
  }
}

export default Video
