import styled from 'styled-components'
import { Link as RouterLink } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import Container from '../common/Container'

const Link = ({ src, to }) => {
  return (
    <RouterLink to={to}>
      <Image src={src} />
    </RouterLink>
  )
}

const ImageLink = styled(Link)``

const Image = styled.img`
  width: 100%;
  border: 1px solid black;
`

const Page = styled.div`
  display: grid;
  grid-template-areas: 'left right right';
  grid-template-columns: 150px auto;
  grid-gap: 30px;

  @media (max-width: 500px) {
    grid-template-areas:
      'right'
      'left';
    grid-template-columns: 1fr;
  }
`

const Left = styled.div`
  grid-area: left;
  display: grid;
  grid-row-gap: 20px;
  margin-top: 25px;
  @media (max-width: 500px) {
    grid-template-columns: 1fr 1fr;
    grid-column-gap: 25px;
  }
`

const Right = styled.div`
  grid-area: right;
  line-height: 1.5;
  font-size: larger;
`

const Home = () => {
  const { t } = useTranslation()
  return (
    <Container>
      <Page>
        <Left>
          <ImageLink to="/docs" src="/images/home/0001.png" />
          <ImageLink to="/objects" src="/images/home/0002.png" />
          <ImageLink to="/images" src="/images/home/0003.png" />
          <ImageLink to="/videos" src="/images/home/0004.png" />
          <ImageLink to="/points" src="/images/home/0005.png" />
          <Image src="/images/home/cc.svg" />
        </Left>
        <Right>
          <p>
            {t(
              'A fa megújuló építőanyagként egyre hangsúlyosabb szerepet kap az építőiparban. Az építészeti tervezésben és kivitelezésben a faépítészet az egyik legfejlődőképesebb irány. Az NKFI MEC_N 141285 pályázat keretében olyan adatbázist készítettünk, ami felhasználható a népszerűsítés mellett a szakmai továbbképzésekhez is.'
            )}
          </p>
          <p>
            {t(
              'Az adatbázis célja, hogy a creative commons által meghatározott alapelvek szerint felhasználható ismeretterjesztő illetve oktatási segédanyagokat lehessen elérni a faépítészet témakörében.'
            )}
          </p>
          <p>
            {t(
              'A pályázat keretében elsődlegesen a legszemléletesebb 3D-s tartalmakat készítettünk el. A honlapon segédprogramok nélkül lehet böngészni térbeli alakzatok, 360-as fényképek és videók valamint pontfelhők között.'
            )}
          </p>
          {t(
            'Az adatbázis egyaránt használható asztali számítógépeken és mobil eszközökön is. A dokumentum jellegű (jegyzetek, segédletek) szabadon le is tölthetőek. Ide a Soproni Egyetem, Faépítészeti Intézete által kezelt segédanyagok is felkerültek.'
          )}
          <p>
            {t(
              'Az oldalt megjelenített példák a soproni Kecskehegyi kilátó különböző adat formátumait mutatja be. A fenti menüsor a tartalmak szerint csoportosított listákhoz vezetnek. Az elérhető adatok között igen nagy méretűek is találhatóak 100 - 300 GB ami mobil eszközökön alacsony adatforgalom mellett lassan töltenek be.'
            )}
          </p>
          <p>
            {t(
              'A honlap 2023. május végéig feldolgozás, optimalizálás alatt van. Az adatbázis mérete miatt még nem a végleges tárhelyen lett kialakítva ami az elérésben akadást jelenthet. Vannak olyan 3D objektumok amik nagyon nagy méretűek, és rossz orientációban jelennek meg, azokat folyamatosan javítjuk.'
            )}
          </p>
        </Right>
      </Page>
    </Container>
  )
}

export default Home
