import styled from 'styled-components'
import { useEffect, useRef, useState } from 'react'
import { useParams } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import { headerHeight } from '../App'
import items from '../data/points.json'

const Wrapper = styled.div`
  background-color: black;
  display: flex;
  flex-direction: column;
  position: relative;
`

const Loading = styled.div`
  position: absolute;
  left: 50%;
  z-index: 100;
  color: white;
  font-size: x-large;
  top: 50%;
`

const Potree = window.Potree

const Point = () => {
  const { id } = useParams()
  const { src } = items[id]
  const { t } = useTranslation()
  const potreeContainerDiv = useRef()
  const [loading, setLoading] = useState(true)

  const [height, setHeight] = useState(() => window.innerHeight - headerHeight)

  const resize = () => {
    setHeight(window.innerHeight - headerHeight)
  }

  useEffect(() => {
    window.addEventListener('resize', resize)

    return () => window.removeEventListener('resize', resize)
  }, [])

  useEffect(() => {
    // initialize Potree viewer
    const viewerElem = potreeContainerDiv.current

    const viewer = new Potree.Viewer(viewerElem)

    viewer.setEDLEnabled(true)
    viewer.setFOV(60)
    viewer.setPointBudget(1 * 1000 * 1000)
    viewer.setClipTask(Potree.ClipTask.SHOW_INSIDE)
    viewer.loadSettingsFromURL()

    viewer.setControls(viewer.orbitControls)

    viewer.loadGUI(() => {
      viewer.setLanguage('en')
      document.getElementById('menu_appearance').next().show()
      viewer.toggleSidebar()
    })

    // Load and add point cloud to scene
    const url = src

    Potree.loadPointCloud(url).then(
      (e) => {
        let pointcloud = e.pointcloud
        let material = pointcloud.material

        material.activeAttributeName = 'rgba'
        material.minSize = 2
        material.pointSizeType = Potree.PointSizeType.FIXED

        viewer.scene.addPointCloud(pointcloud)

        viewer.fitToScreen()

        setLoading(false)
      },
      (e) => console.err('ERROR: ', e)
    )
  }, [])

  return (
    <Wrapper style={{ width: '100%', height: height }} ref={potreeContainerDiv}>
      {loading && <Loading>{t('Loading')}...</Loading>}
    </Wrapper>
  )
}

export default Point
