import 'aframe'
import { useEffect, useState } from 'react'
import { useParams } from 'react-router-dom'
import { useTranslation } from 'react-i18next'

import { headerHeight } from '../App'
import items from '../data/images.json'

// magick convert test.jpg -crop 1x2@ +repage +adjoin pano_%d.png

const StereoPhoto = () => {
  const { id } = useParams()
  const { left, right, vr_title } = items[id]
  const { t } = useTranslation()
  const [shown, setShown] = useState(true)
  const [height, setHeight] = useState(() => window.innerHeight - headerHeight)

  const resize = () => {
    setHeight(window.innerHeight - headerHeight)
  }

  const clickel = () => {
    setShown(false)
  }

  useEffect(() => {
    window.addEventListener('resize', resize)
    window.addEventListener('click', clickel)

    return () => {
      window.removeEventListener('resize', resize)
      window.removeEventListener('click', clickel)
    }
  }, [])

  return (
    <a-scene embedded style={{ width: '100%', height: height }}>
      <a-assets>
        <img id="left" crossOrigin="anonymous" src={left} />
        <img id="right" crossOrigin="anonymous" src={right} />
      </a-assets>

      <a-entity
        camera=""
        look-controls=""
        position="0 0 0"
        stereocam="eye:left;"
        rotation=""
      ></a-entity>

      <a-sky id="sky1" src="#left" stereo="eye:left"></a-sky>
      <a-sky id="sky2" src="#right" stereo="eye:right"></a-sky>
      {shown && (
        <a-text
          font="/fonts/KelsonSansRegular-msdf.json"
          font-image="/fonts/KelsonSansRegular.png"
          negate="false"
          value={t(vr_title)}
          width="6"
          position="0 0 -1.5"
          rotation="0 0 0"
        ></a-text>
      )}
    </a-scene>
  )
}

export default StereoPhoto
