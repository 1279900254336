import { useTranslation } from "react-i18next";
import styled from "styled-components";

const ItemContainer = styled.div`
  height: 100%;
  width: 100%;
  background: rgba(0, 0, 0, 0.06);
  display: flex;
  flex-direction: row;
  cursor: pointer;
  font-family: sans-serif;
  :hover {
    background: rgba(0, 0, 0, 0.2);
  }
`;

const Image = styled.img`
  height: 100%;
  aspect-ratio: 1 / 1;
  object-fit: cover;
  object-position: 50% 50%;
  border: 3px solid black;
`;

const Title = styled.pre`
  width: 100%;
  display: flex;
  font-family: sans-serif;
  margin: 0;
  width: 100%;
  margin: 0;
  white-space: break-spaces;
  overflow: hidden;
  text-overflow: ellipsis;
`;

const Flex = styled.div`
  justify-content: flex-start;
  align-items: flex-start;
  padding: 10px;
  color: initial;
`;

const Description = styled.pre`
  font-family: sans-serif;
  width: 100%;
  margin: 0;
  white-space: break-spaces;
  overflow: hidden;
  text-overflow: ellipsis;
`;

const Item = ({ item, placeholder }) => {
  const { t } = useTranslation();

  return (
    <ItemContainer>
      <Image src={item?.preview || placeholder} />
      <Flex>
        <Title>{t(item?.title)}</Title>
        <Description>{t(item?.description)}</Description>
      </Flex>
    </ItemContainer>
  );
};

export default Item;
