import styled from 'styled-components'
import { useTranslation } from 'react-i18next'

const Image = styled.img`
  max-height: 80px;
  width: auto;
`

const Container = styled.div`
  height: 80px;
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;

  @media (max-width: 600px) {
    flex-direction: column;
  }
`

const Flex = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-start;
  width: 100%;

  @media (max-width: 600px) {
    margin-bottom: 20px;
    width: 100%;
    justify-content: center;
  }
`

const Footer = () => {
  const { i18n } = useTranslation()

  return (
    <Container>
      <Flex>
        <Image src="/images/soe.svg" />
        <div>
          <div>Soproni Egyetem</div>
          <div>MECENATÚRA (MEC_N) 141285</div>
        </div>
      </Flex>
      <Image
        src={
          i18n.language.includes('hu')
            ? '/images/block_hu.png'
            : '/images/block_en.png'
        }
        alt="supporter"
      />
    </Container>
  )
}

export default Footer
