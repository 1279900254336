import { useEffect, useState } from 'react'
import { headerHeight } from '../App'

const YoutubeVideo = ({ videoId }) => {
  const [height, setHeight] = useState(() => window.innerHeight - headerHeight)

  const resize = () => {
    setHeight(window.innerHeight - headerHeight)
  }

  useEffect(() => {
    window.addEventListener('resize', resize)

    return () => window.removeEventListener('resize', resize)
  }, [])

  return (
    <iframe
      width="100%"
      height={height}
      src={`https://www.youtube-nocookie.com/embed/${videoId}?controls=1&modestbranding=1&playsinline=1&color=white&rel=0`}
      title="YouTube video player"
      style={{ border: 0 }}
      allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
      allowFullScreen
    />
  )
}

export default YoutubeVideo
