import { Container, Grid, Item } from '../common'

import items from '../data/docs.json'

const Videos = () => {
  return (
    <Container>
      <Grid>
        {Object.keys(items).map((id) => (
          <a
            target="_blank"
            rel="noreferrer noopener"
            href={items[id].src}
            key={id}
          >
            <Item item={items[id]} placeholder="/doc.svg" />
          </a>
        ))}
      </Grid>
    </Container>
  )
}

export default Videos
