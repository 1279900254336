import AFRAME from 'aframe'
import { stereo_component, stereocam_component } from 'aframe-stereo-component'
import { useEffect, useRef, useState } from 'react'
import { useTranslation } from 'react-i18next'

import { headerHeight } from '../App'

AFRAME.registerComponent('stereo', stereo_component)
AFRAME.registerComponent('stereocam', stereocam_component)

const StereoVideo = ({ src }) => {
  const { t } = useTranslation()

  const video = useRef()
  const [playing, setPlaying] = useState(false)
  const [height, setHeight] = useState(() => window.innerHeight - headerHeight)

  const resize = () => {
    setHeight(window.innerHeight - headerHeight)
  }

  const playback = () => {
    setPlaying(true)
  }

  const pauseback = () => {
    setPlaying(false)
  }

  useEffect(() => {
    window.addEventListener('resize', resize)
    window.addEventListener('click', clickel)
    if (video.current) {
      video.current.addEventListener('play', playback)
      video.current.addEventListener('pause', pauseback)
    }
    return () => {
      window.removeEventListener('click', clickel)
      window.removeEventListener('resize', resize)
      if (video.current) {
        video.current.removeEventListener('play', playback)
        video.current.removeEventListener('pause', pauseback)
      }
    }
  }, [])

  const clickel = () => {
    if (!playing) {
      video.current.play()
    }
    if (playing) {
      video.current.pause()
    }
  }

  return (
    <a-scene embedded style={{ width: '100%', height: height }}>
      <video id="video" ref={video} src={src} autoPlay loop></video>
      <a-camera
        id="camera"
        lookcontrols
        position="0 0 0"
        stereocam="eye:left;split:vertical;"
      >
        {!playing && (
          <a-text
            font="/fonts/KelsonSansRegular-msdf.json"
            font-image="/fonts/KelsonSansRegular.png"
            negate="false"
            value={t('Click or tap to start video')}
            width={6}
            position="0 0 -1.5"
            rotation="0 0 0"
            align="center"
            wrapCount={100}
            color="white"
          ></a-text>
        )}
      </a-camera>
      <a-videosphere
        src="#video"
        stereo="eye:left;split:vertical;"
      ></a-videosphere>
      <a-videosphere
        src="#video"
        stereo="eye:right;split:vertical;"
      ></a-videosphere>
    </a-scene>
  )
}

export default StereoVideo
