import { useTranslation } from 'react-i18next'
import styled from 'styled-components'
import { NavLink as Link } from 'react-router-dom'

const Flex = styled.div`
  display: flex;
  align-items: center;
`

const LanguageBox = styled(Flex)`
  cursor: pointer;
  @media (max-width: 500px) {
    flex-direction: column;
    height: 100%;
    justify-content: space-evenly;
  }
`

const Nav = (props) => (
  <Link
    style={({ isActive }) => ({
      textDecoration: isActive && props.to !== '/' ? 'underline' : 'none',
    })}
    {...props}
  />
)

const NavLink = styled(Nav)`
  margin-left: 10px;
  align-items: center;
  display: flex;
  color: inherit;
  height: var(--header-height);

  @media (max-width: 500px) {
    margin-left: 0;
  }
`

const Justified = styled(Flex)`
  background: lightgray;
  width: 100%;
  height: var(--header-height);
  padding: 0;
  font-size: large;
  display: flex;
  justify-content: space-between;
  align-items: center;
`

const Image = styled.img`
  height: 90%;
`

const Logo = styled(Image)`
  @media (max-width: 500px) {
    margin-left: 5px;
  }
`

const LanguageSwitch = ({ to }) => {
  const { i18n } = useTranslation()

  const change = () => {
    i18n.changeLanguage(to)
  }

  return (
    <LanguageButton onClick={change} current={i18n.language}>
      {to}
    </LanguageButton>
  )
}

const LanguageButton = styled.button`
  background: transparent;
  border: 0;
  outline: none;
  font-size: medium;
  text-decoration: ${({ children, current }) =>
    current.includes(children) ? 'underline' : 'none'};
  cursor: pointer;
  color: initial;
`

const Title = styled.div`
  max-width: 100px;
  font-weight: 600;
  margin-right: 10px;
  line-height: ${({ lang }) => (lang.includes('en') ? 0.9 : 1)};
  margin-left: 10px;

  @media (max-width: 500px) {
    display: none;
  }
`

const Header = () => {
  const { t, i18n } = useTranslation()

  return (
    <Justified>
      <Flex>
        <NavLink to="/">
          <Logo src="/logo.svg" />
          <Title lang={i18n.language}>{t('faépítészeti tudástár')}</Title>
        </NavLink>
        <NavLink to="/docs">
          <Image src="/doc.svg" />
        </NavLink>
        <NavLink to="/objects">
          <Image src="/obj.svg" />
        </NavLink>
        <NavLink to="/images">
          <Image src="/photo.svg" />
        </NavLink>
        <NavLink to="/videos">
          <Image src="/video.svg" />
        </NavLink>
        <NavLink to="/points">
          <Image src="/point.svg" />
        </NavLink>
      </Flex>
      <LanguageBox>
        <LanguageSwitch to="en" />
        <LanguageSwitch to="hu" />
      </LanguageBox>
    </Justified>
  )
}

export default Header
