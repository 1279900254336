import { Link } from 'react-router-dom'
import { Container, Grid, Item } from '../common'

import items from '../data/videos.json'

const Videos = () => {
  return (
    <Container>
      <Grid>
        {Object.keys(items).map((id) => (
          <Link to={id && `/videos/${id}`} key={id}>
            <Item item={items[id]} placeholder="/video.svg" />
          </Link>
        ))}
      </Grid>
    </Container>
  )
}

export default Videos
