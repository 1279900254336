import { createGlobalStyle } from 'styled-components'
import { Route, Routes, BrowserRouter } from 'react-router-dom'

import Header from './Header'
import Footer from './Footer'
import { Model, Point, Video, StereoPhoto } from './common'
import {
  About,
  Documents,
  Home,
  Images,
  Objects,
  Points,
  Videos,
} from './routes'
import { useEffect } from 'react'
import { useTranslation } from 'react-i18next'

export const headerHeight = 60

const GlobalStyle = createGlobalStyle`
  body {
    padding: 0;
    margin: 0;
    font-family: sans-serif;
  }
  * {
    box-sizing: border-box;
  }
  a {
    text-decoration: none;
  }
  :root {
  --header-height: ${headerHeight}px;
  }
`

const App = () => {
  const { t } = useTranslation()
  useEffect(() => {
    document.title = t('faépítészeti tudástár')
  }, [t])

  return (
    <BrowserRouter>
      <GlobalStyle />
      <Header />
      <Routes>
        <Route path="/" element={<Home />} />
        <Route path="/about" element={<About />} />
        <Route path="/images" element={<Images />} />
        <Route path="/videos" element={<Videos />} />
        <Route path="/objects" element={<Objects />} />
        <Route path="/points" element={<Points />} />
        <Route path="/docs" element={<Documents />} />
        <Route path="/points/:id" element={<Point />} />
        <Route path="/images/:id" element={<StereoPhoto />} />
        <Route path="/videos/:id" element={<Video />} />
        <Route path="/objects/:id" element={<Model />} />
      </Routes>
      <Footer />
    </BrowserRouter>
  )
}

export default App
